define('ember-font-awesome/components/fa-icon/component', ['exports', '@ember-decorators/argument', 'ember-font-awesome/components/fa-icon/template'], function (exports, _argument, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _initDefineProp(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _initializerWarningHelper(descriptor, context) {
    throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
  }

  var _desc, _value, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _class2, _temp2;

  var FaIconComponent = (_class = (_temp2 = _class2 = function (_EmberComponent$exte) {
    _inherits(FaIconComponent, _EmberComponent$exte);

    function FaIconComponent() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, FaIconComponent);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = FaIconComponent.__proto__ || Object.getPrototypeOf(FaIconComponent)).call.apply(_ref, [this].concat(args))), _this), _initDefineProp(_this, 'icon', _descriptor, _this), _initDefineProp(_this, 'id', _descriptor2, _this), _initDefineProp(_this, 'class', _descriptor3, _this), _initDefineProp(_this, 'title', _descriptor4, _this), _initDefineProp(_this, 'ariaLabel', _descriptor5, _this), _initDefineProp(_this, 'ariaHidden', _descriptor6, _this), _initDefineProp(_this, 'color', _descriptor7, _this), _initDefineProp(_this, 'click', _descriptor8, _this), _initDefineProp(_this, 'size', _descriptor9, _this), _initDefineProp(_this, 'pull', _descriptor10, _this), _initDefineProp(_this, 'rotate', _descriptor11, _this), _initDefineProp(_this, 'flip', _descriptor12, _this), _initDefineProp(_this, 'stack', _descriptor13, _this), _initDefineProp(_this, 'fixedWidth', _descriptor14, _this), _initDefineProp(_this, 'pulse', _descriptor15, _this), _initDefineProp(_this, 'inverse', _descriptor16, _this), _initDefineProp(_this, 'border', _descriptor17, _this), _initDefineProp(_this, 'spin', _descriptor18, _this), _initDefineProp(_this, 'listItem', _descriptor19, _this), _temp), _possibleConstructorReturn(_this, _ret);
    }

    return FaIconComponent;
  }(Ember.Component.extend({
    layout: _template.default,
    tagName: ''
  })), _class2.positionalParams = ['icon'], _temp2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, 'icon', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, 'id', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, 'class', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, 'title', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, 'ariaLabel', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, 'ariaHidden', [_argument.argument], {
    enumerable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, 'color', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, 'click', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, 'size', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, 'pull', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, 'rotate', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, 'flip', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, 'stack', [_argument.argument], {
    enumerable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, 'fixedWidth', [_argument.argument], {
    enumerable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, 'pulse', [_argument.argument], {
    enumerable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, 'inverse', [_argument.argument], {
    enumerable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, 'border', [_argument.argument], {
    enumerable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, 'spin', [_argument.argument], {
    enumerable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, 'listItem', [_argument.argument], {
    enumerable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class);
  exports.default = FaIconComponent;
});