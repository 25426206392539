enifed('ember-application/system/application-instance', ['exports', 'ember-utils', 'ember-metal', 'ember-environment', 'ember-views', 'ember-application/system/engine-instance', 'ember-glimmer'], function (exports, _emberUtils, _emberMetal, _emberEnvironment, _emberViews, _engineInstance, _emberGlimmer) {
  'use strict';

  /**
    The `ApplicationInstance` encapsulates all of the stateful aspects of a
    running `Application`.
  
    At a high-level, we break application boot into two distinct phases:
  
    * Definition time, where all of the classes, templates, and other
      dependencies are loaded (typically in the browser).
    * Run time, where we begin executing the application once everything
      has loaded.
  
    Definition time can be expensive and only needs to happen once since it is
    an idempotent operation. For example, between test runs and FastBoot
    requests, the application stays the same. It is only the state that we want
    to reset.
  
    That state is what the `ApplicationInstance` manages: it is responsible for
    creating the container that contains all application state, and disposing of
    it once the particular test run or FastBoot request has finished.
  
    @public
    @class ApplicationInstance
    @extends EngineInstance
  */

  /**
  @module @ember/application
  */

  var ApplicationInstance = _engineInstance.default.extend({
    /**
      The `Application` for which this is an instance.
       @property {Application} application
      @private
    */
    application: null,

    /**
      The DOM events for which the event dispatcher should listen.
       By default, the application's `Ember.EventDispatcher` listens
      for a set of standard DOM events, such as `mousedown` and
      `keyup`, and delegates them to your application's `Ember.View`
      instances.
       @private
      @property {Object} customEvents
    */
    customEvents: null,

    /**
      The root DOM element of the Application as an element or a
      [jQuery-compatible selector
      string](http://api.jquery.com/category/selectors/).
       @private
      @property {String|DOMElement} rootElement
    */
    rootElement: null,

    init: function () {
      this._super.apply(this, arguments);

      // Register this instance in the per-instance registry.
      //
      // Why do we need to register the instance in the first place?
      // Because we need a good way for the root route (a.k.a ApplicationRoute)
      // to notify us when it has created the root-most view. That view is then
      // appended to the rootElement, in the case of apps, to the fixture harness
      // in tests, or rendered to a string in the case of FastBoot.
      this.register('-application-instance:main', this, { instantiate: false });
    },
    _bootSync: function (options) {
      var router;

      if (this._booted) {
        return this;
      }

      options = new BootOptions(options);

      this.setupRegistry(options);

      if (options.rootElement) {
        this.rootElement = options.rootElement;
      } else {
        this.rootElement = this.application.rootElement;
      }

      if (options.location) {
        router = (0, _emberMetal.get)(this, 'router');

        (0, _emberMetal.set)(router, 'location', options.location);
      }

      this.application.runInstanceInitializers(this);

      if (options.isInteractive) {
        this.setupEventDispatcher();
      }

      this._booted = true;

      return this;
    },
    setupRegistry: function (options) {
      this.constructor.setupRegistry(this.__registry__, options);
    },

    router: (0, _emberMetal.computed)(function () {
      return this.lookup('router:main');
    }).readOnly(),

    didCreateRootView: function (view) {
      view.appendTo(this.rootElement);
    },
    startRouting: function () {
      var router = (0, _emberMetal.get)(this, 'router');
      router.startRouting();
      this._didSetupRouter = true;
    },
    setupRouter: function () {
      if (this._didSetupRouter) {
        return;
      }
      this._didSetupRouter = true;

      var router = (0, _emberMetal.get)(this, 'router');
      router.setupRouter();
    },
    handleURL: function (url) {
      var router = (0, _emberMetal.get)(this, 'router');

      this.setupRouter();
      return router.handleURL(url);
    },
    setupEventDispatcher: function () {
      var dispatcher = this.lookup('event_dispatcher:main');
      var applicationCustomEvents = (0, _emberMetal.get)(this.application, 'customEvents');
      var instanceCustomEvents = (0, _emberMetal.get)(this, 'customEvents');

      var customEvents = (0, _emberUtils.assign)({}, applicationCustomEvents, instanceCustomEvents);
      dispatcher.setup(customEvents, this.rootElement);

      return dispatcher;
    },
    getURL: function () {
      return (0, _emberMetal.get)(this, 'router.url');
    },
    visit: function (url) {
      var _this = this;

      this.setupRouter();

      var bootOptions = this.__container__.lookup('-environment:main');

      var router = (0, _emberMetal.get)(this, 'router');

      var handleTransitionResolve = function () {
        if (!bootOptions.options.shouldRender) {
          // No rendering is needed, and routing has completed, simply return.
          return _this;
        } else {
          // Ensure that the visit promise resolves when all rendering has completed
          return (0, _emberGlimmer.renderSettled)().then(function () {
            return _this;
          });
        }
      };

      var handleTransitionReject = function (error) {
        if (error.error) {
          throw error.error;
        } else if (error.name === 'TransitionAborted' && router._routerMicrolib.activeTransition) {
          return router._routerMicrolib.activeTransition.then(handleTransitionResolve, handleTransitionReject);
        } else if (error.name === 'TransitionAborted') {
          throw new Error(error.message);
        } else {
          throw error;
        }
      };

      var location = (0, _emberMetal.get)(router, 'location');

      // Keeps the location adapter's internal URL in-sync
      location.setURL(url);

      // getURL returns the set url with the rootURL stripped off
      return router.handleURL(location.getURL()).then(handleTransitionResolve, handleTransitionReject);
    }
  });

  ApplicationInstance.reopenClass({
    setupRegistry: function (registry) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!options.toEnvironment) {
        options = new BootOptions(options);
      }

      registry.register('-environment:main', options.toEnvironment(), { instantiate: false });
      registry.register('service:-document', options.document, { instantiate: false });

      this._super(registry, options);
    }
  });

  /**
    A list of boot-time configuration options for customizing the behavior of
    an `Ember.ApplicationInstance`.
  
    This is an interface class that exists purely to document the available
    options; you do not need to construct it manually. Simply pass a regular
    JavaScript object containing the desired options into methods that require
    one of these options object:
  
    ```javascript
    MyApp.visit("/", { location: "none", rootElement: "#container" });
    ```
  
    Not all combinations of the supported options are valid. See the documentation
    on `Ember.Application#visit` for the supported configurations.
  
    Internal, experimental or otherwise unstable flags are marked as private.
  
    @class BootOptions
    @namespace ApplicationInstance
    @public
  */
  function BootOptions() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    /**
      Provide a specific instance of jQuery. This is useful in conjunction with
      the `document` option, as it allows you to use a copy of `jQuery` that is
      appropriately bound to the foreign `document` (e.g. a jsdom).
       This is highly experimental and support very incomplete at the moment.
       @property jQuery
      @type Object
      @default auto-detected
      @private
    */
    this.jQuery = _emberViews.jQuery; // This default is overridable below

    /**
      Interactive mode: whether we need to set up event delegation and invoke
      lifecycle callbacks on Components.
       @property isInteractive
      @type boolean
      @default auto-detected
      @private
    */
    this.isInteractive = _emberEnvironment.environment.hasDOM; // This default is overridable below

    /**
      Run in a full browser environment.
       When this flag is set to `false`, it will disable most browser-specific
      and interactive features. Specifically:
       * It does not use `jQuery` to append the root view; the `rootElement`
        (either specified as a subsequent option or on the application itself)
        must already be an `Element` in the given `document` (as opposed to a
        string selector).
       * It does not set up an `EventDispatcher`.
       * It does not run any `Component` lifecycle hooks (such as `didInsertElement`).
       * It sets the `location` option to `"none"`. (If you would like to use
        the location adapter specified in the app's router instead, you can also
        specify `{ location: null }` to specifically opt-out.)
       @property isBrowser
      @type boolean
      @default auto-detected
      @public
    */
    if (options.isBrowser !== undefined) {
      this.isBrowser = !!options.isBrowser;
    } else {
      this.isBrowser = _emberEnvironment.environment.hasDOM;
    }

    if (!this.isBrowser) {
      this.jQuery = null;
      this.isInteractive = false;
      this.location = 'none';
    }

    /**
      Disable rendering completely.
       When this flag is set to `true`, it will disable the entire rendering
      pipeline. Essentially, this puts the app into "routing-only" mode. No
      templates will be rendered, and no Components will be created.
       @property shouldRender
      @type boolean
      @default true
      @public
    */
    if (options.shouldRender !== undefined) {
      this.shouldRender = !!options.shouldRender;
    } else {
      this.shouldRender = true;
    }

    if (!this.shouldRender) {
      this.jQuery = null;
      this.isInteractive = false;
    }

    /**
      If present, render into the given `Document` object instead of the
      global `window.document` object.
       In practice, this is only useful in non-browser environment or in
      non-interactive mode, because Ember's `jQuery` dependency is
      implicitly bound to the current document, causing event delegation
      to not work properly when the app is rendered into a foreign
      document object (such as an iframe's `contentDocument`).
       In non-browser mode, this could be a "`Document`-like" object as
      Ember only interact with a small subset of the DOM API in non-
      interactive mode. While the exact requirements have not yet been
      formalized, the `SimpleDOM` library's implementation is known to
      work.
       @property document
      @type Document
      @default the global `document` object
      @public
    */
    if (options.document) {
      this.document = options.document;
    } else {
      this.document = typeof document !== 'undefined' ? document : null;
    }

    /**
      If present, overrides the application's `rootElement` property on
      the instance. This is useful for testing environment, where you
      might want to append the root view to a fixture area.
       In non-browser mode, because Ember does not have access to jQuery,
      this options must be specified as a DOM `Element` object instead of
      a selector string.
       See the documentation on `Ember.Applications`'s `rootElement` for
      details.
       @property rootElement
      @type String|Element
      @default null
      @public
     */
    if (options.rootElement) {
      this.rootElement = options.rootElement;
    }

    // Set these options last to give the user a chance to override the
    // defaults from the "combo" options like `isBrowser` (although in
    // practice, the resulting combination is probably invalid)

    /**
      If present, overrides the router's `location` property with this
      value. This is useful for environments where trying to modify the
      URL would be inappropriate.
       @property location
      @type string
      @default null
      @public
    */
    if (options.location !== undefined) {
      this.location = options.location;
    }

    if (options.jQuery !== undefined) {
      this.jQuery = options.jQuery;
    }

    if (options.isInteractive !== undefined) {
      this.isInteractive = !!options.isInteractive;
    }
  }

  BootOptions.prototype.toEnvironment = function () {
    var env = (0, _emberUtils.assign)({}, _emberEnvironment.environment);
    // For compatibility with existing code
    env.hasDOM = this.isBrowser;
    env.isInteractive = this.isInteractive;
    env.options = this;
    return env;
  };

  exports.default = ApplicationInstance;
});