define('ember-hammertime/mixins/touch-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      defineProperty = Ember.defineProperty,
      get = Ember.get,
      Mixin = Ember.Mixin,
      _Ember$String = Ember.String,
      htmlSafe = _Ember$String.htmlSafe,
      isHTMLSafe = _Ember$String.isHTMLSafe;


  var FocusableInputTypes = ['button', 'submit', 'text', 'file'];
  // Set this to `false` to not apply the styles automatically to elements with an `action`
  var TouchActionOnAction = true;
  // Remove 'onclick' if you do not want the styles automatically applied to elements with an `onclick`
  var TouchActionAttributes = ['onclick'];
  // Remove whichever element types you do not want automatically getting styles applied to them
  var TouchActionSelectors = ['button', 'input', 'a', 'textarea'];
  // The actual style string that is applied to the elements. You can tweak this if you want something different.
  var TouchActionProperties = 'touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;';

  function touchActionStyle() {
    var style = get(this, 'touchActionProperties');
    var otherStyleKey = get(this, 'otherStyleKey');

    if (otherStyleKey) {
      var otherStyle = get(this, otherStyleKey);

      if (otherStyle) {
        if (isHTMLSafe(otherStyle)) {
          otherStyle = otherStyle.string;
        }
        style += otherStyle;
      }
    }

    return htmlSafe(style);
  }

  exports.default = Mixin.create({
    touchActionOnAction: TouchActionOnAction,
    touchActionAttributes: TouchActionAttributes,
    touchActionSelectors: TouchActionSelectors,
    touchActionProperties: TouchActionProperties,
    ignoreTouchAction: false,

    init: function init() {
      var _this = this;

      this._super();

      var tagName = this.tagName,
          ignoreTouchAction = this.ignoreTouchAction,
          click = this.click;


      var hasClick = click && click.apply;
      var hasTag = tagName !== '' || tagName === null && hasClick;
      if (!hasTag) {
        return;
      }

      var maybeApplyStyle = ignoreTouchAction === false;
      var hasClickHandler = ignoreTouchAction === false && hasClick;
      var shouldApplyStyle = false;

      if (maybeApplyStyle) {
        var isFocusable = this.touchActionSelectors.indexOf(tagName) !== -1;

        if (isFocusable && tagName === 'input') {
          isFocusable = FocusableInputTypes.indexOf(this.type) !== -1;
        }

        shouldApplyStyle = isFocusable;
      }

      if (hasClickHandler || shouldApplyStyle) {
        var newAttributeBindings = [];
        var bindings = get(this, 'attributeBindings');

        // don't override other style bindings if present
        if (Array.isArray(bindings)) {
          bindings.forEach(function (binding) {
            if (binding === 'style') {
              _this.otherStyleKey = binding;
            } else {
              var end = binding.length - 6;

              if (end > 0 && ':style' === binding.substring(end)) {
                _this.otherStyleKey = binding.substring(0, end);
              }
            }
          });
          newAttributeBindings = newAttributeBindings.concat(bindings);
        }

        newAttributeBindings.push('touchActionStyle:style');
        this.set('attributeBindings', newAttributeBindings);

        var desc = this.otherStyleKey ? computed(this.otherStyleKey, touchActionStyle) : computed(touchActionStyle);
        defineProperty(this, 'touchActionStyle', desc);
      }
    }
  });
});