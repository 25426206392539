enifed('ember-runtime/system/array_proxy', ['exports', 'ember-metal', 'ember-runtime/utils', 'ember-runtime/system/object', 'ember-runtime/mixins/mutable_array', 'ember-runtime/mixins/array', 'ember-debug'], function (exports, _emberMetal, _utils, _object, _mutable_array, _array, _emberDebug) {
  'use strict';

  exports.default = _object.default.extend(_mutable_array.default, {
    init: function () {
      this._super.apply(this, arguments);
      this._cache = null;
      this._dirtyStart = 0;
      this._arrangedContent = null;
      this._addArrangedContentArrayObsever();
    },
    willDestroy: function () {
      this._removeArrangedContentArrayObsever();
    },

    /**
      The content array. Must be an object that implements `Array` and/or
      `MutableArray.`
       @property content
      @type EmberArray
      @public
    */
    content: null,

    /**
     The array that the proxy pretends to be. In the default `ArrayProxy`
     implementation, this and `content` are the same. Subclasses of `ArrayProxy`
     can override this property to provide things like sorting and filtering.
      @property arrangedContent
     @public
    */
    arrangedContent: (0, _emberMetal.alias)('content'),

    /**
      Should actually retrieve the object at the specified index from the
      content. You can override this method in subclasses to transform the
      content item to something new.
       This method will only be called if content is non-`null`.
       @method objectAtContent
      @param {Number} idx The index to retrieve.
      @return {Object} the value or undefined if none found
      @public
    */
    objectAtContent: function (idx) {
      return (0, _array.objectAt)((0, _emberMetal.get)(this, 'arrangedContent'), idx);
    },
    replace: function (idx, amt, objects) {
      false && !((0, _emberMetal.get)(this, 'arrangedContent') === (0, _emberMetal.get)(this, 'content')) && (0, _emberDebug.assert)('Mutating an arranged ArrayProxy is not allowed', (0, _emberMetal.get)(this, 'arrangedContent') === (0, _emberMetal.get)(this, 'content'));

      this.replaceContent(idx, amt, objects);
    },

    /**
      Should actually replace the specified objects on the content array.
      You can override this method in subclasses to transform the content item
      into something new.
       This method will only be called if content is non-`null`.
       @method replaceContent
      @param {Number} idx The starting index
      @param {Number} amt The number of items to remove from the content.
      @param {EmberArray} objects Optional array of objects to insert or null if no
        objects.
      @return {void}
      @public
    */
    replaceContent: function (idx, amt, objects) {
      (0, _emberMetal.get)(this, 'content').replace(idx, amt, objects);
    },

    // Overriding objectAt is not supported.
    objectAt: function (idx) {
      this._sync();
      return this._cache[idx];
    },

    // Overriding length is not supported.
    length: (0, _emberMetal.computed)(function () {
      this._sync();
      return this._cache.length;
    }),

    _arrangedContentDidChange: (0, _emberMetal.observer)('arrangedContent', function () {
      var oldLength = this._cache === null ? 0 : this._cache.length;
      var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');
      var newLength = arrangedContent ? (0, _emberMetal.get)(arrangedContent, 'length') : 0;

      this._removeArrangedContentArrayObsever();
      this.arrayContentWillChange(0, oldLength, newLength);
      this._dirtyStart = 0;
      this.arrayContentDidChange(0, oldLength, newLength);
      this._addArrangedContentArrayObsever();
    }),

    _addArrangedContentArrayObsever: function () {
      var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');

      if (arrangedContent) {
        false && !(arrangedContent !== this) && (0, _emberDebug.assert)('Can\'t set ArrayProxy\'s content to itself', arrangedContent !== this);
        false && !((0, _utils.isArray)(arrangedContent) || arrangedContent.isDestroyed) && (0, _emberDebug.assert)('ArrayProxy expects an Array or ArrayProxy, but you passed ' + typeof arrangedContent, (0, _utils.isArray)(arrangedContent) || arrangedContent.isDestroyed);

        (0, _array.addArrayObserver)(arrangedContent, this, {
          willChange: '_arrangedContentArrayWillChange',
          didChange: '_arrangedContentArrayDidChange'
        });

        this._arrangedContent = arrangedContent;
      }
    },
    _removeArrangedContentArrayObsever: function () {
      if (this._arrangedContent) {
        (0, _array.removeArrayObserver)(this._arrangedContent, this, {
          willChange: '_arrangedContentArrayWillChange',
          didChange: '_arrangedContentArrayDidChange'
        });
      }
    },
    _arrangedContentArrayWillChange: function () {},
    _arrangedContentArrayDidChange: function (proxy, idx, removedCnt, addedCnt) {
      this.arrayContentWillChange(idx, removedCnt, addedCnt);

      if (this._dirtyStart === undefined) {
        this._dirtyStart = idx;
      } else {
        if (this._dirtyStart > idx) {
          this._dirtyStart = idx;
        }
      }

      this.arrayContentDidChange(idx, removedCnt, addedCnt);
    },
    _sync: function () {
      var arrangedContent, length, i;

      if (this._cache === null) {
        this._cache = [];
      }

      if (this._dirtyStart !== undefined) {
        arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');


        if (arrangedContent) {
          length = (0, _emberMetal.get)(arrangedContent, 'length');


          this._cache.length = length;

          for (i = this._dirtyStart; i < length; i++) {
            this._cache[i] = this.objectAtContent(i);
          }
        } else {
          this._cache.length = 0;
        }

        this._dirtyStart = undefined;
      }
    }
  });
});