enifed("ember-console", ["exports"], function (exports) {
  "use strict";

  /**
    Inside Ember-Metal, simply uses the methods from `imports.console`.
    Override this to provide more robust logging functionality.
  
    @class Logger
    @namespace Ember
    @public
  */

  exports.default = {
    log: function () {
      var _console;

      return (_console = console).log.apply(_console, arguments);
    },
    warn: function () {
      var _console2;

      return (_console2 = console).warn.apply(_console2, arguments);
    },
    error: function () {
      var _console3;

      return (_console3 = console).error.apply(_console3, arguments);
    },
    info: function () {
      var _console4;

      return (_console4 = console).info.apply(_console4, arguments);
    },
    debug: function () {
      var _console6, _console5;

      /* eslint-disable no-console */
      if (console.debug) {

        return (_console5 = console).debug.apply(_console5, arguments);
      }

      return (_console6 = console).info.apply(_console6, arguments);
      /* eslint-enable no-console */
    },
    assert: function () {
      var _console7;

      return (_console7 = console).assert.apply(_console7, arguments);
    }
  };
});